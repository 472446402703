import React, { useEffect, useState } from "react";

export default function FullComnt({ isShow, msg = "" }) {

  const [highlight, setHighlight] = useState(false); // Track if highlight-ball class should be active

  useEffect(() => {
    const lowerMsg = msg.toLowerCase();

    // Check if the msg matches any of the conditions
    if (['ball', 'four', 'six', 'wicket'].includes(lowerMsg)) {
      setHighlight(true); // Add the highlight-ball class

      // Set a timer to remove the class after 2 seconds
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 3000); // 2000 ms = 2 seconds

      // Cleanup the timer when msg changes or the component unmounts
      return () => clearTimeout(timer);
    } else {
      setHighlight(false); // Remove highlight if msg doesn't match
    }
  }, [msg]);
  
  return (
    <>
      {<div className={`live-fulldata ${highlight ? "highlight-ball" : ""}`}>
        <img alt="Ball Running" className={`${msg.toLowerCase() ==='ball' ? 'show' :''}`} src="../images/BallRunning 2.gif" />
        <img alt="four" className={`${msg.toLowerCase() ==='four' ? 'show' :''}`} src="../images/Four.gif" />
        <img alt="six" className={`${msg.toLowerCase() ==='six' ? 'show' :''}`}  src="../images/Six.gif" />
        <img alt="wicket" className={`${msg.toLowerCase() ==='wicket' ? 'show' :''}`}  src="../images/wicket.gif" />
      </div>}
    </>
  );
}
