import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Error from "./Error";
import Loading from "./Loading";
import FullComnt from "./FullComnt";

export default function Main() {
  const { id } = useParams();
  const [matchId, setMatchID] = useState("");
  const [matchData, setMatchData] = useState("");
  const initialized = useRef(false);
  const [isMuted, setIsMuted] = useState(true);
  const audioRef = useRef(null);

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [isLoadPage1, setIsLoadPage1] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [isErrorShow, setIsErrorShow] = useState(false);
  const [matchIDFound, setMatchIDFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [allowedDomains, setAllowedDomains] = useState([]);
  const prevValueRef = useRef();
  const [accessAllowed, setAccessAllowed] = useState(false);
  const [sentence, setSentence] = useState("");
  const [prevSentence, setPrevSentence] = useState("");

  const audioFiles = {
    Four: "../audio/four.m4a",
    Six: "../audio/six.m4a",
    Ball: "../audio/ball.m4a",
    "No Ball": "../audio/no_ball.m4a",
    "Wide Ball": "../audio/wide.m4a",
    Wicket: "../audio/wicket.m4a",
    2: "../audio/two.m4a",
    0: "../audio/zero.m4a",
    1: "../audio/one.m4a",
  };

  const speakSentence = (text) => {
    const synth = window.speechSynthesis;
    synth.cancel(); // Cancel any ongoing speech to avoid overlap
    const utterance = new SpeechSynthesisUtterance(text);

    synth.speak(utterance);
  };

  const handleToggle = () => {
    setIsMuted(!isMuted);
  };

  const getDomainName = (url) => {
    try {
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname;
      console.log(hostname, "-----HostName");

      // Regular expression to match valid IP addresses
      const ipRegex =
        /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;

      // Check if hostname is a valid IP address or a domain
      if (ipRegex.test(hostname) || /^[a-zA-Z0-9.-]+$/.test(hostname)) {
        return hostname;
      } else {
        console.error("Invalid hostname:", hostname);
        return "";
      }
    } catch (error) {
      console.error("Invalid URL:", error);
      return "";
    }
  };

  const callReqCount = (domain) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const raw = JSON.stringify({
      doaminName: domain,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://b2c.supermaster.live/api/v1/requestHistory`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const checkDomain = (list) => {
    let currentDomain = "";
    if (window.location.ancestorOrigins) {
      console.log("Origins ----- ", window.location);
      if (window.location.ancestorOrigins.length - 1 > -1) {
        currentDomain = getDomainName(
          window.location.ancestorOrigins[
            window.location.ancestorOrigins.length - 1
          ]
        );
      } else {
        currentDomain = window.location.hostname;
        console.log(window.location.hostname, "----- HOST NAME ");
      }
    } else {
      if (document.referrer != "") {
        currentDomain = getDomainName(document.referrer);
      } else {
        currentDomain = "";
      }
    }

    const isAllowedDomain =
      list &&
      list.some(
        (item) =>
          item.wlName === currentDomain && item.isActivate && !item.isExpired
      );
    if (isAllowedDomain) {
      setAccessAllowed(true);
      setIsErrorShow(false);
      callMatchIdFind();
      callReqCount(currentDomain);
    } else {
      setIsErrorShow(true);
      setErrorMessage("You Are Not Authorized");
      console.log("You Are Not Authorized", currentDomain);
    }
  };

  const callList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://b2c.supermaster.live/api/v1/whitelabel", requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setAllowedDomains(paresData.data.wl);
        checkDomain(paresData.data.wl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Trigger speech only if the sentence has changed
    if (sentence && sentence !== prevSentence && !isMuted) {
      speakSentence(sentence);
      setPrevSentence(sentence);
    }
  }, [sentence, prevSentence]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callList();
    }
    audioRef.current.muted = isMuted;
    if (matchData && matchData.first_circle) {
      const prevValue = prevValueRef.current;
      const newValue = matchData.first_circle;

      if (audioFiles[newValue]) {
        // Play the specific audio file if it exists
        if (prevValue !== newValue) {
          const audio = audioRef.current;
          audio.src = ""; // Clear any previous source
          audio.src = audioFiles[newValue];
          audio.load(); // Reload the new source
          audio.play().catch((error) => {
            console.error("Audio playback failed:", error);
          });
        }
      } else {
        setSentence(newValue);

        if (prevValue !== newValue) {
          setHasChanged(true); // Values are different
          setTimeout(() => {
            setHasChanged(false);
          }, 1000);
        } else {
          setHasChanged(false); // Values are the same
        }
      }

      // Store current value as previous for the next comparison
      prevValueRef.current = newValue;
    }

    const interval = setInterval(() => {
      if (matchIDFound && accessAllowed) {
        callLiveMatchDetails(matchId);
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [matchData]);

  const callMatchIdFind = () => {
    setIsLoadPage(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
 
    fetch(`https://b2c.supermaster.live/api/v1/matchTvId/${id}`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          setMatchIDFound(true);
          let paresData = await response.json();
          setMatchID(paresData.data.matchId);
          callLiveMatchDetails(paresData.data.matchId);
          setIsErrorShow(false);
        } else {
          setMatchIDFound(false);
          setIsErrorShow(true);
          setErrorMessage("Match Not Found");
        }
        setIsLoadPage(false);
      })
      .catch((error) => {
        setMatchIDFound(false);
        setIsLoadPage(false);
        setIsErrorShow(true);
        setErrorMessage("Match Not Found");
        console.log(error);
      });
  };

  const callLiveMatchDetails = (match_id) => {
    if (!isFirstTime) {
      setIsFirstTime(true);
      setIsLoadPage1(true);
    }
    const formdata = new FormData();
    formdata.append("match_id", match_id);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://apicricketchampion.in/apiv4/liveMatch/${process.env.REACT_APP_TOKEN}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        if (paresData.status) {
          setMatchData(paresData.data);
          setIsErrorShow(false);
        } else {
          // setIsErrorShow(true);
          setErrorMessage("Match Not Found");
        }
        setIsLoadPage1(false);
      })
      .catch((error) => {
        setIsLoadPage1(false);
        // setIsErrorShow(true);
        setErrorMessage("Match Not Found");
      });
  };

  const createBatsMenTable = (batsmenData) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Batters</th>
            <th>R</th>
            <th>B</th>
            <th>4s</th>
            <th>6s</th>
            <th>S/R</th>
          </tr>
        </thead>
        <tbody>
          {batsmenData.map((batsman, index) => (
            <tr key={index}>
              <td>{batsman.name}</td>
              <td>{batsman.run}</td>
              <td>{batsman.ball}</td>
              <td>{batsman.fours}</td>
              <td>{batsman.sixes}</td>
              <td>{batsman.strike_rate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const createBolwerTable = (bolwer) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Bolwer</th>
            <th>O</th>
            <th>R</th>
            <th>W</th>
            <th>EC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bolwer && bolwer?.name}</td>
            <td>{bolwer && bolwer?.over}</td>
            <td>{bolwer && bolwer?.run}</td>
            <td>{bolwer && bolwer?.wicket}</td>
            <td>{bolwer && bolwer?.economy}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getFontSize = () => {
    let length = matchData?.first_circle?.length;
    let fontSize;

    if (length > 20) {
      fontSize = "sm-fn";
    } else if (length > 15) {
      fontSize = "md-fn";
    } else if (length > 10) {
      fontSize = "lg-fn";
    } else if (length > 5) {
      fontSize = "x-lg-fn";
    } else {
      fontSize = "xx-lg-fn";
    }
    return fontSize;
  };

  const scoreTeamBScore = (matchData) => {
    if (matchData.match_type === "Test") {
      if (
        matchData.team_b_scores_over !== null &&
        Array.isArray(matchData.team_b_scores_over) &&
        matchData.team_b_scores_over.length === 2
      ) {
        return (
          <>
            {matchData.team_b_scores_over.map((item, index) => (
              <span key={index}>
                {item.score} {index == 0 ? "&" : scoreTeamBOver()}
              </span>
            ))}
          </>
        );
      } else {
        return matchData.team_b_scores_over != null ? (
          <span>
            {matchData.team_b_scores_over[0].score}
            {scoreTeamBOver()}
          </span>
        ) : (
          <span>YET TO BET</span>
        );
      }
    } else {
      return matchData.team_b_scores_over != null ? (
        <span>
          {matchData.team_b_scores_over[0].score}
          {scoreTeamBOver()}
        </span>
      ) : (
        <span>YET TO BET</span>
      );
    }
  };

  const scoreTeamAScore = (matchData) => {
    if (matchData.match_type === "Test") {
      if (
        matchData.team_a_scores_over !== null &&
        Array.isArray(matchData.team_a_scores_over) &&
        matchData.team_a_scores_over.length === 2
      ) {
        return (
          <>
            {matchData.team_a_scores_over.map((item, index) => (
              <span key={index}>
                {item.score} {index == 0 ? "&" : scoreTeamAOver()}
              </span>
            ))}
          </>
        );
      } else {
        return matchData.team_a_scores_over != null ? (
          <span>
            {matchData.team_a_scores_over[0].score}
            {scoreTeamAOver()}
          </span>
        ) : (
          <span>YET TO BET</span>
        );
      }
    } else {
      return matchData.team_a_scores_over != null ? (
        <span>
          {matchData.team_a_scores_over[0].score}
          {scoreTeamAOver()}
        </span>
      ) : (
        <span>YET TO BET</span>
      );
    }
  };

  const scoreTeamAOver = () => {
    if (matchData.match_type === "Test") {
      if (
        matchData.team_a_scores_over !== null &&
        matchData.team_a_scores_over.length === 2
      ) {
        return `(${
          matchData.team_a_scores_over[matchData.team_a_scores_over.length - 1]
            .over
        })`;
      } else {
        if (matchData.team_a_scores_over != null) {
          return `(${matchData.team_a_scores_over[0].over})`;
        } else {
          return "";
        }
      }
    } else {
      if (matchData.team_a_scores_over != null) {
        return `(${matchData.team_a_scores_over[0].over})`;
      } else {
        return "";
      }
    }
  };

  const scoreTeamBOver = () => {
    if (matchData.match_type === "Test") {
      if (
        matchData.team_b_scores_over !== null &&
        matchData.team_b_scores_over.length === 2
      ) {
        return `(${
          matchData.team_b_scores_over[matchData.team_b_scores_over.length - 1]
            .over
        })`;
      } else {
        if (matchData.team_b_scores_over != null) {
          return `(${matchData.team_b_scores_over[0].over})`;
        } else {
          return "";
        }
      }
    } else {
      if (matchData.team_b_scores_over != null) {
        return `(${matchData.team_b_scores_over[0].over})`;
      } else {
        return "";
      }
    }
  };

  const checkUpcomming = () => {
    if (matchData && matchData.toss === "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="App">
        <div
          className={`scoreboard-frame ${checkUpcomming() ? "upcoming" : ""}`}
        >
          <audio ref={audioRef} />
          <Loading isLoading={isLoadPage || isLoadPage1} />
          <Error isshow={isErrorShow} msg={errorMessage} />
          {accessAllowed ? (
            <>
              <video
                width="320"
                height="240"
                autoPlay
                loop
                muted
                className="background-video"
              >
                <source src="../video/bg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {<FullComnt msg={matchData.first_circle} />}
              <div className="sf-wraper">
                <div className="sf-header sf-match-toss-result">
                  {matchData && matchData.toss}
                  <span className="match-format">
                    {matchData && matchData.match_type}
                  </span>
                </div>
                <div className="sf-match-result">
                  <div className="sf-mr-box">
                    <div className="sf-team-details">
                      <div className="sf-score-details">
                        <div className="sf-team-name">
                          <span className="flag">
                            <img
                              alt="Team A"
                              src={matchData && matchData.team_a_img}
                            />
                          </span>
                          <span className="team-s-name">
                            {matchData.team_a_short}
                          </span>
                          <span className="team-l-name">
                            {matchData.team_a}
                          </span>
                          {matchData.batting_team == matchData.team_a_id ? (
                            <span className="current_play_status">
                              <img alt="batting" src="../images/bat.png" />
                            </span>
                          ) : (
                            <span className="current_play_status">
                              <img
                                alt="ball"
                                className="ball-icon"
                                src="../images/ball.png"
                              />
                            </span>
                          )}
                        </div>
                        <div className="sf-run-detail">
                          <div className="sf-team-run">
                            {scoreTeamAScore(matchData)}
                          </div>
                          {matchData &&
                          matchData.team_a_id == matchData.batting_team ? (
                            <div className="sf-crr">
                              {matchData.match_type === "Test" ? (
                                ""
                              ) : (
                                <>
                                  CRR : {matchData && matchData.curr_rate}
                                  {matchData &&
                                  matchData.current_inning != "1" ? (
                                    <span className="sf-rrr">
                                      RRR : {matchData && matchData.rr_rate}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="sf-player-current-status">
                        {matchData &&
                        matchData.team_a_id == matchData.batting_team ? (
                          <>{createBatsMenTable(matchData.batsman)}</>
                        ) : (
                          <>{createBolwerTable(matchData.bolwer)}</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`sf-mr-box ${
                      matchData.current_inning ? "inning-show" : ""
                    }`}
                  >
                    <div
                      className={`sf-ball-result-effect ${
                        hasChanged ? "updated" : ""
                      }`}
                    >
                      <div
                        className={`ball-result ${
                          hasChanged ? "updated" : ""
                        } ${getFontSize()}`}
                      >
                        <span>
                          {matchData.first_circle != null &&
                          matchData.toss !== ""
                            ? matchData.first_circle
                            : "Match is not Live"}
                        </span>
                      </div>
                      {matchData.match_type === "Test" ? (
                        <div className="trial-lead">{matchData.trail_lead}</div>
                      ) : matchData.current_inning != "1" &&
                        matchData.result === "" ? (
                        <div className="trial-lead">
                          {matchData.need_run_ball}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="sf-mr-box">
                    <div className="sf-team-details right">
                      <div className="sf-score-details">
                        <div className="sf-team-name">
                          <span className="flag">
                            <img
                              alt="Team b"
                              src={matchData && matchData.team_b_img}
                            />
                          </span>
                          <span className="team-s-name">
                            {matchData.team_b_short}
                          </span>
                          <span className="team-l-name">
                            {matchData.team_b}
                          </span>
                          {matchData.batting_team == matchData.team_b_id ? (
                            <span className="current_play_status">
                              <img alt="batting" src="../images/bat.png" />
                            </span>
                          ) : (
                            <span className="current_play_status">
                              <img
                                alt="ball"
                                className="ball-icon"
                                src="../images/ball.png"
                              />
                            </span>
                          )}
                        </div>
                        <div className="sf-run-detail">
                          <div className="sf-team-run">
                            {scoreTeamBScore(matchData)}
                          </div>
                          {matchData &&
                          matchData.team_b_id == matchData.batting_team ? (
                            <div className="sf-crr">
                              CRR : {matchData && matchData.curr_rate}
                              {matchData && matchData.current_inning != "1" ? (
                                <span className="sf-rrr">
                                  RRR : {matchData && matchData.rr_rate}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="sf-player-current-status">
                        {matchData &&
                        matchData.team_b_id == matchData.batting_team ? (
                          <>{createBatsMenTable(matchData.batsman)}</>
                        ) : (
                          <>{createBolwerTable(matchData.bolwer)}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sf-footer">
                  <div className="sf-overs">
                    {Array.isArray(matchData?.last4overs) &&
                    matchData?.last4overs.length === 1
                      ? matchData?.last4overs[0].balls.map(
                          (ballItem, ballInde) => (
                            <span key={ballItem} className="ball">
                              {ballItem}
                            </span>
                          )
                        )
                      : Array.isArray(matchData?.last4overs) &&
                        matchData?.last4overs.length > 1 && (
                          <>
                            {matchData?.last4overs[
                              matchData?.last4overs.length - 2
                            ].balls.map((ballItem, ballIndex1) => (
                              <span
                                className={`ball prv-ovr ${
                                  ballItem.toLowerCase() === "w"
                                    ? "loss-ball"
                                    : ""
                                } ${
                                  ballItem.toLowerCase() === "4" ? "four" : ""
                                } ${
                                  ballItem.toLowerCase() === "6" ? "six" : ""
                                }`}
                                key={ballIndex1}
                              >
                                {ballItem}
                              </span>
                            ))}
                            <span className="seprator"></span>
                            {matchData?.last4overs[
                              matchData?.last4overs.length - 1
                            ].balls.map((ballItem, ballIndex2) => (
                              <>
                                <span
                                  className={`ball ${
                                    ballItem.toLowerCase() === "w"
                                      ? "loss-ball"
                                      : ""
                                  } ${
                                    ballItem.toLowerCase() === "4" ? "four" : ""
                                  } ${
                                    ballItem.toLowerCase() === "6" ? "six" : ""
                                  }`}
                                  key={ballIndex2}
                                >
                                  {ballItem}
                                </span>
                              </>
                            ))}
                          </>
                        )}
                  </div>
                </div>
              </div>
              <div
                onClick={handleToggle}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "116px",
                }}
              >
                {isMuted ? (
                  <img
                    src="../images/mute.png"
                    alt="Mute"
                    style={{
                      height: "30px",
                      width: "30px",
                      filter: "invert(1)",
                    }}
                  />
                ) : (
                  <img
                    src="../images/volume.png"
                    alt="Unmute"
                    style={{
                      height: "30px",
                      width: "30px",
                      filter: "invert(1)",
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
