import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function EditNamePopups(props) {
  const [lable, setLable] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isActivate, setActivate] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState("1-d");
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const oneMonthInMilliseconds = 30.44 * 24 * 60 * 60 * 1000;

  const timeAdd = (item) => {
    const currentDate = new Date();
    const expirationDate = new Date(item && item.expireTime);
    let time = 0;
  
    const timeDifference = expirationDate.getTime() - currentDate.getTime(); // Time difference in milliseconds
    const daysDifference = Math.floor(
      Math.abs(timeDifference) / (1000 * 60 * 60 * 24)
    ); // Convert to days

    if (timeDifference <= 0) {
      if (daysDifference === 0) {
        time = item && item.expireTime;
      } else {
        time = new Date().getTime();
      }
    } else {
      time = item && item.expireTime;
    }

    let currentMilliseconds = time;
    if (selectedTypes === "1-d") {
      currentMilliseconds = currentMilliseconds + oneDayInMilliseconds;
    } else if (selectedTypes === "1-w") {
      currentMilliseconds = currentMilliseconds + oneWeekInMilliseconds;
    } else if (selectedTypes === "15-d") {
      currentMilliseconds = currentMilliseconds + 15 * oneDayInMilliseconds;
    } else if (selectedTypes === "1-m") {
      currentMilliseconds = currentMilliseconds + oneMonthInMilliseconds;
    } else if (selectedTypes === "2-m") {
      currentMilliseconds = currentMilliseconds + 2 * oneMonthInMilliseconds;
    } else if (selectedTypes === "3-m") {
      currentMilliseconds = currentMilliseconds + 3 * oneMonthInMilliseconds;
    } else if (selectedTypes === "4-m") {
      currentMilliseconds = currentMilliseconds + 4 * oneMonthInMilliseconds;
    } else if (selectedTypes === "5-m") {
      currentMilliseconds = currentMilliseconds + 5 * oneMonthInMilliseconds;
    } else if (selectedTypes === "6-m") {
      currentMilliseconds = currentMilliseconds + 6 * oneMonthInMilliseconds;
    } else if (selectedTypes === "7-m") {
      currentMilliseconds = currentMilliseconds + 7 * oneMonthInMilliseconds;
    } else if (selectedTypes === "8-m") {
      currentMilliseconds = currentMilliseconds + 8 * oneMonthInMilliseconds;
    } else if (selectedTypes === "9-m") {
      currentMilliseconds = currentMilliseconds + 9 * oneMonthInMilliseconds;
    } else if (selectedTypes === "10-m") {
      currentMilliseconds = currentMilliseconds + 10 * oneMonthInMilliseconds;
    } else if (selectedTypes === "11-m") {
      currentMilliseconds = currentMilliseconds + 11 * oneMonthInMilliseconds;
    } else if (selectedTypes === "12-m") {
      currentMilliseconds = currentMilliseconds + 12 * oneMonthInMilliseconds;
    }
    return currentMilliseconds;
  };

  const handleActivate = (event) => {
    setActivate(event.target.value === "Active");
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (props && props.currentItem) {
      setLable(props.currentItem.wlName);
      setActivate(props.currentItem.isActivate);
    }
  }, [props]);

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value);
  };

  const handleAddClick = () => {
    updateWhiteList(props.currentItem);
  };

  const updateWhiteList = (item) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let data = `{
                ${
                  isChecked
                    ? `"expireTime": ${isChecked ? timeAdd(item) : ""},`
                    : ""
                }
                "isActivate": ${isActivate}
                ${isChecked ? `,"isExpired":false` : ""}
            }`;

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(
      `https://b2c.supermaster.live/api/v1/whitelabel/${item._id}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        props.addSuccessfully();
        props.onHide();
        props.clearItem();
        setIsChecked(false);
        setLable("");
        console.log(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = () => {
    props.onHide();
    setIsChecked(false);
  };

  const formatTimefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  const formetsTillEnds = (item) => {
    const currentDate = new Date();
    const expirationDate = new Date(item && item.expireTime);

    const timeDifference = expirationDate.getTime() - currentDate.getTime(); // Time difference in milliseconds

    if (timeDifference <= 0) {
      return "-";
    } else {
      return formatTimefromAPI(expirationDate);
    }
  };

  return (
    <>
      <style>{` <style>
        body {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            background-color: #f0f0f0;
        }

        .form-container {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
        }

        input:not([type="checkbox"]):not([type="radio"]), select {
            padding: 8px;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
            margin-bottom: 15px;
        }
        
        input[type="text"], select {
            padding: 8px;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
            margin-bottom: 15px;
        }
    </style>`}</style>
      <Modal {...props}>
        <Modal.Header>
          <Modal.Title>Update Whitelist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <label for="inputField">Domain Name:</label>
            <input
              type="text"
              id="inputField"
              disabled
              value={lable}
              onChange={(e) => setLable(e.target.value)}
              name="inputField"
            />
            <div>
              <div style={{ textAlign: "right",fontSize: "12px" }}>
                <span  style={{ color: 'red' }}>
                  <b>Till End date:</b>
                  {props && !isChecked ? formetsTillEnds(props.currentItem) : formatTimefromAPI(timeAdd(props.currentItem))}
                </span>
              </div>

              <span>
                Extend plan{" "}
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </span>
            </div>
            {isChecked ? (
              <div>
                <label for="dropdown">Add More Duration:</label>
                <select
                  id="dropdown"
                  name="dropdown"
                  value={selectedTypes}
                  onChange={handleTypeChange}
                >
                  <option value="1-d">1 Day</option>
                  <option value="1-w">1 Week</option>
                  <option value="15-d">15 Days</option>
                  <option value="1-m">1 Month</option>
                  <option value="2-m">2 Months</option>
                  <option value="3-m">3 Months</option>
                  <option value="4-m">4 Months</option>
                  <option value="5-m">5 Months</option>
                  <option value="6-m">6 Months</option>
                  <option value="7-m">7 Months</option>
                  <option value="8-m">8 Months</option>
                  <option value="9-m">9 Months</option>
                  <option value="10-m">10 Months</option>
                  <option value="11-m">11 Months</option>
                  <option value="12-m">12 Months</option>
                </select>
              </div>
            ) : (
              ""
            )}
            <span className="radio-center">
              <label className="radio-item" for="ative">
                <input
                id="ative"
                  type="radio"
                  checked={isActivate}
                  value="Active"
                  onChange={handleActivate}
                />{" "}Active
              </label>

              <label className="radio-item" for="block">
                <input
                id="block"
                  type="radio"
                  value="Inactive"
                  checked={!isActivate}
                  onChange={handleActivate}
                />{" "}Block
              </label>
            </span>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="view" onClick={handleClose}>
            Close
          </Button>
          <Button className="view-submit" onClick={handleAddClick}>
            Update WhiteList
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
