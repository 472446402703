import React, { useState } from "react";
import "../../assets/css/admin.css";
import AdminNav from "./AdminNav";
import SideNav from "./SideNav";
import Whitelisted from "./Whitelisted";
import Home from "./Home";
import Reports from "./Reports";
import Demo from "./demo";

export default function AdminDashboard() {
  const [activeName, setActiveName] = useState("Home");

  const handleWhiteList = () => {
    setActiveName("Whitelisted");
  };

  const handleHome = () => {
    setActiveName("Home");
  };

  const handleUserAnalysis = () => {
    setActiveName("report");
  };
  
  const handleLogout = () => {
    setActiveName("daywise");
  };

  const renderComponent = () => {
    switch (activeName) {
      case "Home":
        return <Home />;
      case "Whitelisted":
        return <Whitelisted />;
      case "report":
        return <Reports />;
        case "daywise":
          return <Demo />;
      default:
        return "";
    }
  };

  return (
    <div className="adminbody">
      <AdminNav />
      <div className="main-container">
        <SideNav
          activeName={activeName}
          handleHome={handleHome}
          handleWhiteList={handleWhiteList}
          handleUserAnalysis={handleUserAnalysis}
          handleLogout={handleLogout}
        />
        <div class="main">{renderComponent()}</div>
      </div>
    </div>
  );
}
