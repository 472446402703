import { useNavigate } from "react-router-dom";
import "../../assets/css/admin.css";
import React from 'react'

export default function SideNav({activeName,handleHome,handleWhiteList,handleUserAnalysis,handleLogout}) {
  return (
    <div className="navcontainer">
          <nav className="nav">
            <div className="nav-upper-options">
              <div className={`nav-option ${activeName==='Home' ? 'option1':''}`}  onClick={handleHome}>
                <img
                  src="/images/home.png"
                  className={`nav-img ${activeName==='Home' ? 'image-white':''}`}
                  alt="Home"
                />
                <h3>Home</h3>
              </div>

              <div className={`option2 nav-option ${activeName==='Whitelisted' ? 'option1':''}`} onClick={handleWhiteList}>
                <img
                  src="/images/click.png"
                  className={`nav-img ${activeName==='Whitelisted' ? 'image-white':''}`}
                  alt="Whitelisted"
                />
                  <h3>Whitelisted</h3>
              </div>

              <div className={`nav-option option3 ${activeName==='report' ? 'option1':''}`} onClick={handleUserAnalysis}>
                <img
                  src="/images/data-analysis.png"
                  className={`nav-img ${activeName==='report' ? 'image-white':''}`}
                  alt="report"
                />
                  <h3>Analysis</h3>
              </div>

              <div className={`nav-option option3 ${activeName==='daywise' ? 'option1':''}`} onClick={handleLogout}>
                <img
                  src="/images/data-analysis.png"
                  className={`nav-img ${activeName==='daywise' ? 'image-white':''}`}
                  alt="logout"
                />
                <h3>DayWise</h3>
              </div>

              <div className="nav-option logout">
                <img
                  src="/images/logout.png"
                  className="nav-img"
                  alt="logout"
                />
                <h3>Logout</h3>
              </div>
            </div>
          </nav>
        </div>

  )
}
