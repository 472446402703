import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === "admin" && password === "123456789") {
      navigate("/adminDashboard");
    } else {
      alert("Username and Passwrod Wrong");
    }
  };
  return (
    <>
      <style>{`body {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: sans-serif;
          line-height: 1.5;
          min-height: 100vh;
          background: #333;
          flex-direction: column;
          margin: 0;
      }

      .mainn {
          background-color: #fff;
          border-radius: 15px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          padding: 10px 20px;
          transition: transform 0.2s;
          width: 500px;
          text-align: center;
      }

      h1 {
          color: #02a9a9;
      }

      label {
          display: block;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 5px;
          text-align: left;
          color: #555;
          font-weight: bold;
      }


      input {
          display: block;
          width: 100%;
          margin-bottom: 15px;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #ddd;
          border-radius: 5px;
      }

      button {
          padding: 15px;
          border-radius: 10px;
          margin-top: 15px;
          margin-bottom: 15px;
          border: none;
          color: white;
          cursor: pointer;
          background-color: #02a9a9;
          width: 100%;
          font-size: 16px;
      }

      .wrap {
          display: flex;
          justify-content: center;
          align-items: center;
      }`}</style>
      <div className="App">
      <video
            width="320"
            height="240"
            autoPlay
            loop
            muted
            className="background-video"
          >
            <source src="../video/bg.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        <div class="mainn">
          <h1>Admin Login</h1>

          <label for="first">Username:</label>
          <input
            type="text"
            id="first"
            name="first"
            placeholder="Enter your Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label for="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div class="wrap">
            <button type="submit" onClick={handleLogin}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
