import React from 'react'
import "../../assets/css/admin.css";

export default function AdminNav() {
  return (
    <header>
        <div class="logosec">
          <div class="logo">Live Scorecard</div>
        </div>
      </header>
  )
}
