import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/admin.css";

export default function Reports() {
  const initialized = useRef(false);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedTypes, setSelectedTypes] = useState("today");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateMilli, setFromDateMilli] = useState(0);
  const [toDateMilli, setToDateMilli] = useState(0);

  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const [whiteList, setWhiteList] = useState([]);
  const [reqList, setReqList] = useState();

  const handleDomainChange = (event) => {
    setSelectedDomain(event.target.value);
    let Stimes, Etime;
    if (selectedTypes === "cd") {
      Stimes = fromDateMilli;
      Etime = toDateMilli;
    } else if (selectedTypes === "today") {
      let ctime = new Date();
      ctime.setHours(0, 0, 0, 0);
      Stimes = ctime.getTime();
      Etime = 0;
    } else {
      let number = parseInt(selectedTypes, null);
      let ctime = new Date();
      ctime.setHours(0, 0, 0, 0);
      let mili = ctime.getTime() - number;
      Stimes = mili;
      Etime = 0;
    }
    callDateWiseList(event.target.value, Stimes, Etime);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      let ctime = new Date();
      ctime.setHours(0, 0, 0, 0);
      setFromDateMilli(ctime.getTime());
      callDateWiseList(selectedDomain, ctime.getTime(), toDateMilli);
      callWhiteList();
    }
  }, [reqList]);

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedTypes(value);
    setFromDate(null);
    setToDate(null);
    setToDateMilli(0);

    if (value === "cd") {
      setFromDateMilli(0);
      setDatePickerVisible(true);
    } else if (value === "today") {
      let ctime = new Date();
      ctime.setHours(0, 0, 0, 0);
      callDateWiseList(selectedDomain, ctime.getTime(), 0);
      setDatePickerVisible(false);
    } else {
      let number = parseInt(e.target.value, null);
      let ctime = new Date();
      ctime.setHours(0, 0, 0, 0);
      let mili = ctime.getTime() - number;
      callDateWiseList(selectedDomain, mili, 0);
      setDatePickerVisible(false);
    }
  };

  const callWhiteList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`https://b2c.supermaster.live/api/v1/whitelabel`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setWhiteList(paresData.data.wl);
        console.log(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callDateWiseList = (doaminName, fromDate, toDate) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://b2c.supermaster.live/api/v1/requestHistory?${
        doaminName ? `domainName=${doaminName}` : ""
      }${fromDate > 0 ? `&fromDate=${fromDate}` : ""}${
        toDate > 0 ? `&toDate=${toDate}` : ""
      }`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        setReqList(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ cursor: "pointer" }}
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      placeholder="dd-MM-yyyy"
    />
  ));

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  return (
    <>
      <div
        style={{
          backgroundColor: `#ffffff`,
          borderRadius: `30px`,
          boxShadow: `3px 3px 10px rgb(188, 188, 188)`,
          padding: `0px 20px 20px 20px`,
          margin: "43px",
          maxHeight: `calc(100vh - 200px - 156px)`,
          overflow: `auto`,
        }}
      >
        <div>
          <div class="report-header">
            <h1 class="recent-Articles">Whitelist</h1>

            <div>
              <span>
                <label for="dropdown">
                  <b>Filter: </b>
                </label>
                <select
                  id="dropdown"
                  name="dropdown"
                  className="view"
                  value={selectedTypes}
                  onChange={handleTypeChange}
                >
                  <option value="today">Last Today</option>
                  <option value="172800000">Last 48 hours</option>
                  <option value="259200000">Last 72 hours</option>
                  <option value="604800000">Last Week</option>
                  <option value="2592000000">Last Month</option>
                  <option value="cd">Custom Date</option>
                </select>
                {isDatePickerVisible && (
                  <>
                    <span>
                      {"  "}From Date:{" "}
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDate(date);
                          setFromDateMilli(date.getTime());
                          callDateWiseList(
                            selectedDomain,
                            date.getTime(),
                            toDateMilli
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                        customInput={<CustomInput />}
                        maxDate={new Date()}
                      />
                    </span>
                    <span></span>
                    <span>
                      {"  "}To Date:{" "}
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => {
                          setToDate(date);
                          setToDateMilli(date.getTime());
                          callDateWiseList(
                            selectedDomain,
                            fromDateMilli,
                            date.getTime()
                          );
                        }}
                        dateFormat="dd-MM-yyyy"
                        customInput={<CustomInput />}
                        minDate={
                          fromDate
                            ? new Date(fromDate.getTime() + 24 * 60 * 60 * 1000)
                            : null
                        }
                        maxDate={new Date()}
                        disabled={!fromDate}
                      />
                    </span>
                  </>
                )}
                {"   "}
                <select
                  id="dropdown"
                  name="dropdown"
                  className="view"
                  value={selectedDomain}
                  onChange={handleDomainChange}
                >
                  <option value="">All</option>
                  {whiteList && whiteList.length > 0 ?
                    whiteList.map((item, index) => (
                      <option key={index} value={`${item.wlName}`}>
                        {item.wlName}
                      </option>
                    )):'History not found....'}
                </select>
              </span>
            </div>
          </div>

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>WhiteList</th>
                <th>Requests</th>
              </tr>
            </thead>
            <tbody>
              {reqList &&
                reqList.data &&
                reqList.data.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.doaminName}</td>
                    <td>{formatDate(item.date)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
