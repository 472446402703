import "../src/assets/css/cricket.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./component/Main";
import Error from "./component/Error";
import Login from "./component/adminPannel/Login";
import AdminDashboard from "./component/adminPannel/AdminDashboard";

function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<Main />} />
        <Route path="/adminLogin" element={<Login />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route
          path="/"
          element={
            <div className="App">
              <div className="scoreboard-frame">
                <Error isshow={true} msg={"You Are Not Authorized"} />
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
    </>
  );
}
export default App;
