import React, { useEffect, useState } from "react";
import { addDays, subDays, isToday } from "date-fns";
import DatePicker from "react-datepicker";
import Graph from "./Graph";

export default function Demo() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState();
  const [selectedDomain, setSelectedDomain] = useState("");
  const max = new Date();
  const [whiteList, setWhiteList] = useState([]);

  const handleDomainChange = (event) => {
    setSelectedDomain(event.target.value);
    callAPI(selectedDate.getTime(),event.target.value);
  }

  const callWhiteList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`https://b2c.supermaster.live/api/v1/whitelabel`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setWhiteList(paresData.data.wl);
        console.log(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    callAPI(selectedDate.getTime(),selectedDomain);
    callWhiteList();
    const interval = setInterval(() => {
      callAPI(selectedDate.getTime(),selectedDomain);
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedDate,selectedDomain]);

  const formattedDate = selectedDate;

  const handlePrevDate = () => {
    setSelectedDate((prevDate) => subDays(prevDate, 1));
  };

  const handleNextDate = () => {
    setSelectedDate((prevDate) => addDays(prevDate, 1));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const isNextDisabled = isToday(selectedDate);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="view-date"
      style={{ cursor: "pointer" }}
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      placeholder="dd-MM-yyyy"
    />
  ));

  const callAPI = (date,selectedDomain) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://b2c.supermaster.live/api/v1/requestHistory/graph?selectedDate=${date}&domainName=${selectedDomain}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        setData(paresData);
        console.log(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: `#ffffff`,
          borderRadius: `30px`,
          boxShadow: `3px 3px 10px rgb(188, 188, 188)`,
          padding: `0px 20px 20px 20px`,
          margin: "43px",
          height: `calc(100% - 156px)`,
          overflow: `auto`,
        }}
      >
        <div>
          <div>
            <div class="report-header">
              <h1 class="recent-Articles">Whitelist Graph</h1>
              <div className="side-filtter">
               
               <div>
               <select
                  id="dropdown"
                  name="dropdown"
                  className="dropdown-view"
                  value={selectedDomain}
                  onChange={handleDomainChange}
                >
                  <option value="">All</option>
                  {whiteList && whiteList.length > 0
                    ? whiteList.map((item, index) => (
                        <option key={index} value={`${item.wlName}`}>
                          {item.wlName}
                        </option>
                      ))
                    : "History not found...."}
                </select>
               </div>

                <div>
                  <button className="view-btn" onClick={handlePrevDate}>
                    <b>&#60;</b>
                  </button>
                  <DatePicker
                    selected={formattedDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                    customInput={<CustomInput />}
                    maxDate={max}
                  />

                  <button
                    className="view-btn"
                    onClick={handleNextDate}
                    disabled={isNextDisabled}
                  >
                    <b>&#62;</b>
                  </button>
                </div>
              </div>
            </div>

            <div>
              <Graph resp={data && data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
