import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";

export default function DeletePopups(props) {
    const handleDelete = () => {
        deleteWhiteList();
    }

    const deleteWhiteList = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
          method: "DELETE",
          redirect: "follow",
        };
    
        fetch(
          `https://b2c.supermaster.live/api/v1/whitelabel/${props.currentItem._id}`,
          requestOptions
        )
          .then(async (response) => {
            let paresData = await response.json();
            props.addSuccessfully();
            props.onHide();
            props.clearItem();
            console.log(paresData);
          })
          .catch((error) => {
            props.onHide();
            console.log(error);
          });
      };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirm Deletion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure to delete this?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="view" onClick={props.onHide}>Cancel</Button>
        <Button className="view-delete" onClick={handleDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}
