import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const Graph = ({resp}) => {
  useEffect(() => {
    const ctx = document.getElementById('stackedBarChart1').getContext('2d');

    const data1 = {
      labels:resp && resp.hours,
      datasets: resp && resp.data,
    };

    const config1 = {
      type: 'bar',
      data: data1,
      options: {
        responsive: true,
        animation: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };

    // Create the chart
    const stackedBarChart1 = new Chart(ctx, config1);

    // Cleanup function to destroy the chart on component unmount
    return () => {
      stackedBarChart1.destroy();
    };
  }, [resp]);

  return (
    <div>
      <canvas id="stackedBarChart1" style={{  maxHeight: '400px', width: '100%', height: 'auto' }} />
    </div>
  );
};

export default Graph;
