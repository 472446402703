import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AddNamePopups(props) {
  const [lable, setLable] = useState('');
  const [selectedTypes, setSelectedTypes] = useState("1-d");

  const handleAddClick = () => {
    if(lable !== ''){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const raw = JSON.stringify({
          wlName: lable,
          expireTime:selectedTypes
        });
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        fetch("https://b2c.supermaster.live/api/v1/whitelabel", requestOptions)
        .then(async (response) => {
            let paresData = await response.json();
            props.addSuccessfully()
            props.onHide();
            props.clearItem();
            setLable('')
            console.log(paresData)
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
        alert('Please Enter Domain Name or IP Address.')
    }
  };

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value);
  };
  return (
    <>
    <style>{` <style>
        body {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
            background-color: #f0f0f0;
        }

        .form-container {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
        }
    </style>`}</style>
    <Modal {...props} >
      <Modal.Header>
        <Modal.Title>Add Whitelist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
            <label for="inputField">Enter Domain Name:</label>
            <input type="text" id="inputField" value={lable}
            onChange={(e) => setLable(e.target.value)} name="inputField"/>

            <label for="dropdown">Select Duration:</label>
            <select id="dropdown" name="dropdown" value={selectedTypes} onChange={handleTypeChange}>
                <option value="1-d">1 Day</option>
                <option value="1-w">1 Week</option>
                <option value="15-d">15 Days</option>
                <option value="1-m">1 Month</option>
                <option value="2-m">2 Months</option>
                <option value="3-m">3 Months</option>
                <option value="4-m">4 Months</option>
                <option value="5-m">5 Months</option>
                <option value="6-m">6 Months</option>
                <option value="7-m">7 Months</option>
                <option value="8-m">8 Months</option>
                <option value="9-m">9 Months</option>
                <option value="10-m">10 Months</option>
                <option value="11-m">11 Months</option>
                <option value="12-m">12 Months</option>
            </select>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="view" onClick={props.onHide}>
          Close
        </Button>
        <Button className="view-submit" onClick={handleAddClick}>
          Add New WhiteList
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}
