import "../../assets/css/admin.css";
import React, { useEffect, useRef, useState } from "react";
import DeletePopups from "./popups/DeletePopups";
import AddNamePopups from "./popups/AddNamePopups";
import EditNamePopups from "./popups/EditNamePopups";

export default function Whitelisted() {
  const initialized = useRef(false);
  const [whiteList, setWhiteList] = useState([]);
  const [addedName, setAddedName] = useState(false);
  const [isAddLable, setIsAddLable] = useState(false);
  const [isDeleteShow, setIsDeleteShow] = useState(false);
  const [isEditShow, setIsEditShow] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  const formatTimefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  const formatExpireTime = (item) => {
    const currentDate = new Date();
    const expirationDate = new Date(item.expireTime);
  
    const timeDifference = expirationDate.getTime() - currentDate.getTime(); // Time difference in milliseconds
  
    if (timeDifference <= 0) {
      return `Expired`;
    }
  
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
    const hoursDifference = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Hours left
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60); // Minutes left
    const secondsDifference = Math.floor((timeDifference / 1000) % 60); // Seconds left
  
    if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""} remaining`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} remaining`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} minute${minutesDifference > 1 ? "s" : ""} remaining`;
    } else {
      return `${secondsDifference} second${secondsDifference > 1 ? "s" : ""} remaining`;
    }
  };

  const formetsTillEnds = (item) => {
    const currentDate = new Date();
    const expirationDate = new Date(item.expireTime);

    const timeDifference = expirationDate.getTime() - currentDate.getTime(); // Time difference in milliseconds

    if (timeDifference <= 0) {
      return "-";
    } else {
      return formatTimefromAPI(expirationDate);
    }
  };

  const callList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://b2c.supermaster.live/api/v1/whitelabel", requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setWhiteList(paresData.data.wl);
        removeSuccessfully();
        console.log(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callList();
    }
    if (addedName) {
      callList();
      removeSuccessfully();
      setIsAddLable(false);
    }
  }, [whiteList, addedName]);

  const addSuccessfully = () => {
    setAddedName(true);
  };

  const removeSuccessfully = () => {
    setAddedName(false);
  };

  const handleAddLable = () => {
    setIsAddLable(!isAddLable);
  };

  const handleEdit = (item) => {
    setIsEditShow(true);
    setCurrentItem(item);
  };

  const clearItem = () => {
    setCurrentItem("");
  };

  return (
    <div>
      <DeletePopups
        show={isDeleteShow}
        onHide={() => setIsDeleteShow(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <AddNamePopups
        show={isAddLable}
        onHide={() => setIsAddLable(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <EditNamePopups
        show={isEditShow}
        onHide={() => setIsEditShow(false)}
        currentItem={currentItem}
        addSuccessfully={addSuccessfully}
        clearItem={clearItem}
      />

      <div class="report-container">
        <div class="report-header">
          <h1 class="recent-Articles">Whitelist</h1>
          <button onClick={handleAddLable} class="view">
            Add WhiteList
          </button>
        </div>

        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>WhiteList</th>
              <th>Created At</th>
              <th>Expire On</th>
              <th>Till end date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {whiteList &&
              whiteList.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.wlName}</td>
                  <td>{formatTimefromAPI(item.createdAt)}</td>
                  <td>{formatExpireTime(item)}</td>
                  <td>{formetsTillEnds(item)}</td>
                  <td>
                    {
                      <span className="tl-status">
                        <span
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: `${
                              item.isActivate ? "green" : "red"
                            }`,
                            display: "inline-block",
                            borderRadius: "50%",
                          }}
                        />
                        {item.isActivate ? " Active" : " Block"}
                      </span>
                    }
                  </td>
                  <td>
                    <span>
                      <span onClick={() => handleEdit(item)}>
                        <img
                          src="/images/edit.png"
                          width="30px"
                          height="30px"
                          alt="Edit"
                        />
                      </span>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
