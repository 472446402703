import "../../assets/css/admin.css";
import React, { useEffect, useRef, useState } from "react";

export default function Home() {
  const initialized = useRef(false);
  const [whiteList, setWhiteList] = useState();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callList();
    }
  }, [whiteList]);

  const callList = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://b2c.supermaster.live/api/v1/whitelabel", requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setWhiteList(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div class="box-container">
        <div class="box box1">
          <div class="text">
            <h2 class="topic-heading">{whiteList && whiteList.reqCount}</h2>
            <h2 class="topic">Total Request</h2>
          </div>

          <img
            src="/images/data-transfer.png"
            className="image-white"
            alt="Views"
          />
        </div>

        <div class="box box2">
          <div class="text">
            <h2 class="topic-heading">{whiteList && whiteList.results}</h2>
            <h2 class="topic">Total Domain</h2>
          </div>

          <img
            src="/images/checklist.png"
            className="image-white"
            alt="likes"
          />
        </div>

        <div class="box box3">
          <div class="text">
            <h2 class="topic-heading">{whiteList && whiteList.liveDomainCount}</h2>
            <h2 class="topic">Live Domain</h2>
          </div>

          <img src="/images/live.png" className="image-white" alt="comments" />
        </div>

        <div class="box box4">
          <div class="text">
            <h2 class="topic-heading">{whiteList && whiteList.exDomainCount}</h2>
            <h2 class="topic">Expired Domain</h2>
          </div>

          <img
            src="/images/clock.png"
            className="image-white"
            alt="published"
          />
        </div>

        <div class="box box5">
          <div class="text">
            <h2 class="topic-heading">{whiteList && whiteList.blockCount}</h2>
            <h2 class="topic">Block Domain</h2>
          </div>

          <img
            src="/images/cloud-computing.png"
            className="image-white"
            alt="published"
          />
        </div>
      </div>
    </>
  );
}
